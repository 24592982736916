export default {
  profilesideval (store) {
    return store.closebarprofile
  },
  cartsideval (store) {
    return store.closebarcart
  },
  viewinfoval (store) {
    return store.closebarstoreinfo
  },
  cartBag (store) {
    return store.cartBag
  }

}
