export default {
  popupshow (store) {
    return store.mainpopup
  },
  editpopupval (store) {
    return store.editpopup
  },
  getthankupopval (store) {
    return store.thankyoupopup
  }
}
