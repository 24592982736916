export default {
  searchVal (state) {
    return state.search
  },
  menslide (state) {
    return state.menslide
  },
  womenslide (state) {
    return state.womenslide
  }
}
